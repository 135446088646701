exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-about-about-js": () => import("./../../../src/pages/sections/about/about.js" /* webpackChunkName: "component---src-pages-sections-about-about-js" */),
  "component---src-pages-sections-footer-footer-js": () => import("./../../../src/pages/sections/footer/footer.js" /* webpackChunkName: "component---src-pages-sections-footer-footer-js" */),
  "component---src-pages-sections-galery-galery-jsx": () => import("./../../../src/pages/sections/galery/galery.jsx" /* webpackChunkName: "component---src-pages-sections-galery-galery-jsx" */),
  "component---src-pages-sections-galery-modal-components-btn-action-jsx": () => import("./../../../src/pages/sections/galery/modal/components/btnAction.jsx" /* webpackChunkName: "component---src-pages-sections-galery-modal-components-btn-action-jsx" */),
  "component---src-pages-sections-galery-modal-modal-jsx": () => import("./../../../src/pages/sections/galery/modal/modal.jsx" /* webpackChunkName: "component---src-pages-sections-galery-modal-modal-jsx" */),
  "component---src-pages-sections-header-header-js": () => import("./../../../src/pages/sections/header/header.js" /* webpackChunkName: "component---src-pages-sections-header-header-js" */),
  "component---src-pages-sections-intro-intro-js": () => import("./../../../src/pages/sections/intro/intro.js" /* webpackChunkName: "component---src-pages-sections-intro-intro-js" */),
  "component---src-pages-sections-nav-nav-js": () => import("./../../../src/pages/sections/nav/nav.js" /* webpackChunkName: "component---src-pages-sections-nav-nav-js" */),
  "component---src-pages-sections-services-catalogo-equipos-js": () => import("./../../../src/pages/sections/services/CatalogoEquipos.js" /* webpackChunkName: "component---src-pages-sections-services-catalogo-equipos-js" */),
  "component---src-pages-sections-services-otrosyclientes-jsx": () => import("./../../../src/pages/sections/services/otrosyclientes.jsx" /* webpackChunkName: "component---src-pages-sections-services-otrosyclientes-jsx" */),
  "component---src-pages-sections-services-services-js": () => import("./../../../src/pages/sections/services/services.js" /* webpackChunkName: "component---src-pages-sections-services-services-js" */)
}

